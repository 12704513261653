import { Link } from 'gatsby'

import { ServicesProcessMock } from '~/types/mock'

const ChatgptDevProcess: ServicesProcessMock[] = [
  {
    num: '01',
    title: 'Product discovery',
    descr: (
      <>
        Our
        <Link
          to="/services/product-discovery/"
          className="mx5"
          key="keyTitleProductDiscovery"
        >
          product discovery
        </Link>
        phase involves thorough research and consultations. We learn about your
        goals, audience, and the pains your ChatGPT app needs to address.
      </>
    ),
  },
  {
    num: '02',
    title: 'UI/UX design',
    descr: (
      <>
        With our
        <Link to="/services/ui-ux-design/" className="ml5" key="keyTitleDesign">
          UI/UX design and development services
        </Link>
        , Codica’s specialists work closely with you to compose an intuitive and
        attractive interface for your ChatGPT application.
      </>
    ),
  },
  {
    num: '03',
    title: 'Development',
    descr:
      'Based on a well-defined plan, our developers build your ChatGPT app from scratch. We are at the forefront of AI technology and integrate ChatGPT seamlessly into your app.',
  },
  {
    num: '04',
    title: 'Rigorous testing',
    descr: (
      <>
        Before the application’s launch, our quality engineers subject it to
        <Link
          to="/services/quality-assurance/"
          className="ml5"
          key="keyTitleQA"
        >
          QA testing services
        </Link>
        . It is about functional testing, performance testing, security testing,
        and more.
      </>
    ),
  },
  {
    num: '05',
    title: 'Support',
    descr:
      'We provide continuous support to guarantee your ChatGPT app remains in optimal condition. Our experts address bugs, implement updates, and make ongoing improvements.',
  },
]

export default ChatgptDevProcess
