import { ExpertiseSectionMock } from '~/types/mock'

const ChatgptDevServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_10',
    title: 'Consulting',
    description:
      'We work closely with you to prepare a tailored strategy that aligns with your business goals. Our team defines the scope and creates a roadmap to guide your ChatGPT deployment.',
  },
  {
    icon: 'sprite3DIcon_11',
    title: 'Custom ChatGPT development',
    description:
      'We have expertise in merging ChatGPT into websites, mobile apps, CRM systems, and other communication channels, enabling engaging interaction with your customers.',
  },
  {
    icon: 'sprite3DIcon_15',
    title: 'ChatGPT integration',
    description:
      'We have expertise in merging ChatGPT into websites, mobile apps, CRM systems, and other communication channels, enabling engaging interaction with your customers.',
  },
  {
    icon: 'sprite3DIcon_22',
    title: 'ChatGPT modernization',
    description:
      'Codica ensures your ChatGPT models remain state-of-the-art through continuous improvements. We keep your conversational agents adapting to shifting user behaviors.',
  },
  {
    icon: 'sprite3DIcon_19',
    title: 'Independent testing',
    description:
      'We conduct rigorous testing of ChatGPT models, focusing on accuracy and compliance with industry standards. Our testing framework ensures your conversational AI is reliable.',
  },
  {
    icon: 'sprite3DIcon_20',
    title: 'Maintenance and support',
    description:
      'Deploying a ChatGPT solution is just the beginning. We provide ongoing maintenance and support, addressing issues, implementing updates, and improving continuously.',
  },
]

export default ChatgptDevServices
