// extracted by mini-css-extract-plugin
export var chatgptCasesExpertiseSection = "L_g5";
export var chatgptCasesSection = "L_g8";
export var chatgptDevExpertiseSection = "L_g9";
export var chatgptIndustriesSection = "L_g3";
export var chatgptPrimeSection = "L_g1";
export var chatgptProcessSection = "L_g6";
export var chatgptQuotesSection = "L_hc";
export var chatgptServicesSection = "L_g2";
export var chatgptTechStackSection = "L_g7";
export var chatgptTrustedSection = "L_g4";
export var chatgptWhyChooseSection = "L_hb";