import { HomeSpecification } from '~/types/mock'

const ChatgptDevExpertise: HomeSpecification[] = [
  {
    icon: 'sprite3DIconSmall_10',
    title: 'Online marketplaces',
    description:
      'Enhance your users’ shopping journey with personalized recommendations, product inquiries, and seamless transactions.',
    link: '/case-studies/kids-activities-service-marketplace/',
  },
  {
    icon: 'sprite3DIconSmall_13',
    title: 'eCommerce',
    description:
      'Empower your e-commerce platform with instant support, order tracking, and product information, facilitating seamless shopping experiences.',
    link: '/case-studies/custom-multi-vendor-marketplace-platform/',
  },
  {
    icon: 'sprite3DIconSmall_14',
    title: 'Travel',
    description:
      'Provide travelers with real-time assistance, itinerary planning, and travel tips, all while adapting to the industry’s ever-changing landscape.',
    link: '/case-studies/travel-management-platform/',
  },
  {
    icon: 'sprite3DIconSmall_15',
    title: 'Automotive',
    description:
      'From helping customers find the perfect vehicle to planning maintenance, you can facilitate the car-buying and ownership journey.',
    link: '/services/automotive-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_4',
    title: 'Insurance',
    description:
      'Facilitate instant quotes, policy information, and claims assistance, ensuring customers have the support they need when it matters most.',
    link: '/services/insurance-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_11',
    title: 'Recruiting',
    description:
      'Provide candidate screening, job matching, and answering inquiries, enabling recruiters to focus on the talent acquisition aspects.',
    link: '/case-studies/recruitment-progressive-web-application/',
  },
  {
    icon: 'sprite3DIconSmall_16',
    title: 'Rental',
    description:
      'Manage reservations, answer queries about available options, and provide instant support, enhancing the rental experience for your customers.',
    link: '/case-studies/accommodation-search-website-mvp/',
  },
  {
    icon: 'sprite3DIconSmall_17',
    title: 'Media',
    description:
      'Deliver personalized content recommendations, reply to audience questions, and provide real-time news updates, fostering engagement and trust.',
    link: '/case-studies/news-aggregator/',
  },
  {
    icon: 'sprite3DIconSmall_18',
    title: 'Healthcare',
    description:
      'Deliver information about symptoms, appointment scheduling, and medical advice, offering support and reassurance to patients.',
    link: '/case-studies/dental-imaging-management-system/',
  },
]

export default ChatgptDevExpertise
