import { ExpertiseSectionMock } from '~/types/mock'

const ChatgptDevTrusted: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_26',
    title: 'Deep domain knowledge',
    description:
      'At Codica, we understand your industry. With extensive learning across various sectors, we develop ChatGPT models that are contextually aware, ensuring your conversational agent speaks the language of your business.',
  },
  {
    icon: 'spriteIcon_71',
    title: 'Tailor-made solutions',
    description:
      'We uniquely craft customized ChatGPT models carefully tailored to your needs. Whether you’re in e-commerce, healthcare, or any other industry, our solutions are meticulously designed to meet your business goal.',
  },
  {
    icon: 'spriteIcon_10',
    title: 'Budget-friendly approach',
    description:
      'Our specialists believe in delivering high-value ChatGPT solutions without compromising your budget. So, we are committed to offering cost-effective AI development that maximizes your ROI (return on investment).',
  },
  {
    icon: 'spriteIcon_102',
    title: 'Agile development',
    description:
      'Flexibility is crucial in a constantly growing world. Our iterative approach enables us to adapt to changing requirements swiftly and efficiently. Moreover, this approach ensures that ChatGPT evolves with your business.',
  },
  {
    icon: 'spriteIcon_3',
    title: 'Effortless system integration',
    description:
      'The success of your ChatGPT relies on how seamlessly it integrates into your existing ecosystem. Our expertise ensures that the integration process is smooth, causing minimal disruption to your operations.',
  },
  {
    icon: 'spriteIcon_27',
    title: 'Sustained support and ongoing enhancement',
    description:
      'Launching your ChatGPT is just the start of our partnership. We provide constant support and improvements. Our team keeps your AI up-to-date, ensuring it remains a valuable asset that increases alongside your business.',
  },
]

export default ChatgptDevTrusted
