import { ExpertiseSectionMock } from '~/types/mock'

const ChatgptDevBusinessBenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_74',
    title: 'Automation',
    description:
      'ChatGPT handles redundant and time-consuming tasks with remarkable precision, allowing human resources to focus on more complex and strategic endeavors.',
  },
  {
    icon: 'spriteIcon_65',
    title: 'Multilingual capabilities',
    description:
      'The multilingual nature of ChatGPT enables businesses to overcome language barriers by offering seamless translation and communication, expanding market reach.',
  },
  {
    icon: 'spriteIcon_42',
    title: 'Personalization',
    description:
      'ChatGPT understands user preferences, making it possible to offer tailored recommendations, content, and responses, driving brand loyalty.',
  },
  {
    icon: 'spriteIcon_66',
    title: 'Innovation and creativity',
    description:
      'As a valuable resource for brainstorming, ChatGPT can generate fresh content ideas, innovative product suggestions, and creative solutions to problems.',
  },
  {
    icon: 'spriteIcon_15',
    title: 'Scalability',
    description:
      'Whether your business is a small startup, medium, or global corporation, ChatGPT scales effortlessly to meet the increased demand for interactions and support.',
  },
  {
    icon: 'spriteIcon_16',
    title: 'Cost savings',
    description:
      'By automating tasks, enhancing efficiency, and lowering the need for manual labor, ChatGPT can lead to considerable cost savings while improving the bottom line.',
  },
]

export default ChatgptDevBusinessBenefits
