import { getImage } from 'gatsby-plugin-image'

import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import LazyHydrate from '~/components/shared/LazyHydrate'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/chatgpt-development/Anchors'
import ChatgptDevBusinessBenefits from '~/mock/services/chatgpt-development/ChatgptDevBusinessBenefits'
import ChatgptDevExpertise from '~/mock/services/chatgpt-development/ChatgptDevExpertise'
import ChatgptDevFAQ from '~/mock/services/chatgpt-development/ChatgptDevFAQ'
import ChatgptDevProcess from '~/mock/services/chatgpt-development/ChatgptDevProcess'
import ChatgptDevServices from '~/mock/services/chatgpt-development/ChatgptDevServices'
import ChatgptDevTrusted from '~/mock/services/chatgpt-development/ChatgptDevTrusted'
import MvpAppDevExpertise from '~/mock/services/mvp-app-development/MvpAppDevExpertise'
import { fileToImageLikeData } from '~/utils'
import useChatGPTDevelopmentStaticQuery from '~/views/Services/ChatGPTDevelopment/useChatGPTDevelopmentStaticQuery'
import ServicesChatGptExpertiseSection from '~/views/Services/components/ServicesChatGptExpertiseSection'
import ServicesDevExpertiseCompany from '~/views/Services/components/ServicesDevExpertiseCompany'
import ServicesFullVideoSection from '~/views/Services/components/ServicesFullVideoSection'
import ServicesProcess from '~/views/Services/components/ServicesProcess'
import ServicesTechStackChatGPTSection from '~/views/Services/components/ServicesTechStackChatGPTSection'

import * as containerStyles from './ChatGPTDevelopment.module.scss'

const ChatGPTDevelopment = () => {
  const query = useChatGPTDevelopmentStaticQuery()
  const clientRef = getImage(fileToImageLikeData(query.clientRef))
  const fullVideoImage = getImage(fileToImageLikeData(query.fullVideoImage))

  return (
    <MainLayout scrollTop>
      <section className={containerStyles.chatgptPrimeSection}>
        <PrimeSectionServices
          titleTop="ChatGPT Development"
          title="Services"
          description="Welcome to a world where your software can understand, respond, and engage users naturally and personally. Let's explore the unlimited options of ChatGPT together!"
          breadcrumbSecondLevel="Services"
          breadcrumbSecondLevelPath="/services/"
          breadcrumbCurrent="ChatGPT Development Services"
          breadcrumbCurrentPath="/services/chatgpt-development-services/"
        />
      </section>

      <StickyNavigation anchors={Anchors} />

      <section className={containerStyles.chatgptServicesSection} id="services">
        <HeadSection
          position="center"
          title="Our comprehensive ChatGPT development services"
          description="Codica, a reliable name in artificial intelligence (AI) solutions, offers an extensive suite of ChatGPT development services. These services will help you transform customer interactions, streamline operations, and drive your success in the digital landscape."
          descriptionLarge
        />
        <Service3DIconCard dataContent={ChatgptDevServices} />
      </section>

      <section
        id="industries"
        className={containerStyles.chatgptIndustriesSection}
      >
        <HeadSection
          position="center"
          title="Tailored industry-specific ChatGPT solutions"
          description="With our ChatGPT development company, you can be confident that your conversational AI will meet your industry's needs and set you apart as a leader in customer engagement and service excellence."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={ChatgptDevExpertise} />
      </section>

      <CallToAction
        title="Ready to transform your business with ChatGPT?"
        subtitle="Let’s explore the endless possibilities of ChatGPT development together."
        classNameForGA="servicesCTA_1"
        linkName="Contact us"
        link="/contacts/"
      />

      <section className={containerStyles.chatgptTrustedSection}>
        <HeadSection
          position="center"
          title="Take advantage of a trusted ChatGPT development company"
          description="In the dynamic landscape of AI and conversational agents, Codica stands out as the dedicated full-cycle development partner, offering many benefits that set us apart. With our advanced development services, your ChatGPT application is poised for excellence!"
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard dataContent={ChatgptDevTrusted} />
      </section>

      <section
        id="benefits"
        className={containerStyles.chatgptCasesExpertiseSection}
      >
        <HeadSection
          position="center"
          title="Our advanced expertise in many ChatGPT use cases"
          description="At Codica, our commitment to harnessing the potential of ChatGPT extends far and wide. Our unique ChatGPT development services are tailored to meet your specific requirements, ensuring that your clients benefit from the limitless possibilities of conversational AI."
          descriptionLarge
        />
        <ServicesChatGptExpertiseSection />
      </section>

      <section className={containerStyles.chatgptProcessSection} id="process">
        <HeadSection
          position="center"
          title="Our step-by-step ChatGPT apps development process"
          description="Codica's detailed ChatGPT development process is a testament to our dedication to excellence. Our experts guarantee every step of ChatGPT development services is executed with precision, care, and innovation."
          descriptionLarge
        />
        <ServicesProcess dataContent={ChatgptDevProcess} />
      </section>

      <section
        id="tech-stack"
        className={containerStyles.chatgptTechStackSection}
      >
        <HeadSection
          position="center"
          title="Optimal tech stack to create a ChatGPT application"
          description="Developing a ChatGPT application demands the proper technological foundation that serves as the basis for an exceptional user experience and seamless functionality. At Codica, our experts understand the significance of selecting the cutting-edge tech stack to deliver ChatGPT development services that meets and exceeds your business expectations."
          descriptionLarge
        />

        <ServicesTechStackChatGPTSection />
      </section>

      <CallToAction
        title="Want to boost your business with ChatGPT?"
        subtitle="We specialize in ChatGPT development services to make your ideas a reality."
        classNameForGA="servicesCTA_2"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section className={containerStyles.chatgptCasesSection} id="cases">
        <HeadSection
          position="center"
          title="Our thrilling case studies"
          description="At Codica, our passion lies in turning challenging problems into remarkable success stories."
        />
        <LazyHydrate whenVisible>
          <ServicesFullVideoSection
            videoLink="https://www.youtube.com/embed/A8Bd1aiwpRU"
            videoImage={fullVideoImage}
            videoTitle="Our case studies"
          />
        </LazyHydrate>
      </section>

      <section className={containerStyles.chatgptDevExpertiseSection}>
        <HeadSection
          position="center"
          title="Why choose Codica for your ChatGPT app development"
          description="Preferring Codica means choosing innovation, efficiency, and a reliable partner dedicated to making your ChatGPT app stand out. Let's start this development journey with our ChatGPT applications development company!"
          descriptionLarge
        />
        <ServicesDevExpertiseCompany dataContent={MvpAppDevExpertise} />
      </section>

      <section className={containerStyles.chatgptWhyChooseSection}>
        <HeadSection
          position="center"
          title="Essential business benefits of ChatGPT development"
          description="In today's digital landscape, ChatGPT development services offer many critical benefits for businesses, moving them toward greater efficiency and effectiveness in various domains. ChatGPT catalyzes businesses looking to thrive in an increasingly competitive world."
          descriptionLarge
        />
        <ServicesIconSmallCard dataContent={ChatgptDevBusinessBenefits} />
      </section>

      <section className={containerStyles.chatgptQuotesSection}>
        <HeadSection
          position="center"
          title="Client testimonials"
          description="At Codica, we take immense pride in our ChatGPT development services, and our clients’ feedback is the ultimate testament to our commitment to excellence. Here's what some valued clients say about their experience working with the Codica team."
          descriptionLarge
        />
        <div className="container container-md">
          <ClientVideoQuotes
            name="Leon Zinger"
            location="Israel"
            position="Founder at RefDental"
            image={clientRef}
            quote={[
              {
                text: '“What we like most about working with Codica is their deep understanding of our business needs. They quickly grasped our initial idea and delivered our Minimum Viable Product within the specified time and budget. Thanks to this, we could get user feedback fast”',
              },
            ]}
            link="dental-imaging-management-system"
            videoLink="https://www.youtube.com/embed/r1tbx4MEmrY"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s develop an exciting ChatGPT app for your business idea!"
          widthTitle="700px"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our software developers will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={ChatgptDevFAQ} />
      </section>
    </MainLayout>
  )
}

export default ChatGPTDevelopment
