import { NavTechStackMock, TechStackMock } from '~/types/mock'

export const NavTechStack: NavTechStackMock[] = [
  {
    title: 'OpenAI Models',
  },
  {
    title: 'AI Frameworks',
  },
  {
    title: 'Cloud Platforms',
  },
  {
    title: 'Integration and Deployment',
  },
  {
    title: 'Programming Languages',
  },
  {
    title: 'Databases',
  },
]

export const TechStack: TechStackMock[] = [
  {
    title: 'OpenAI Models',
    techStack: [
      { icon: 'dalleTwo', name: 'DALL.E 2' },
      { icon: 'gptThree', name: 'GPT-3' },
      { icon: 'clarity', name: 'CLARITY' },
      { icon: 'curie', name: 'Curie.ai' },
      { icon: 'jukebox', name: 'Jukebox' },
    ],
  },
  {
    title: 'AI Frameworks',
    techStack: [
      { icon: 'tensorflow', name: 'TensorFlow' },
      { icon: 'pytorch', name: 'PyTorch' },
      { icon: 'keras', name: 'Keras' },
    ],
  },
  {
    title: 'Cloud Platforms',
    techStack: [
      { icon: 'aws', name: 'AWS' },
      { icon: 'googleCloudPlatform', name: 'Google Cloud Platform' },
      { icon: 'azureBlue', name: 'Azure' },
    ],
  },
  {
    title: 'Integration and Deployment',
    techStack: [
      { icon: 'docker', name: 'Docker' },
      { icon: 'kubernetes', name: 'Kubernetes' },
      { icon: 'ansible', name: 'Ansible' },
    ],
  },
  {
    title: 'Programming Languages',
    techStack: [
      { icon: 'python', name: 'Python' },
      { icon: 'javascript', name: 'JavaScript' },
      { icon: 'r', name: 'R' },
      { icon: 'ruby', name: 'Ruby' },
    ],
  },
  {
    title: 'Databases',
    techStack: [
      { icon: 'postgresql', name: 'PostgreSQL' },
      { icon: 'mysql', name: 'MySQL' },
      { icon: 'mongodb', name: 'Mongo DB' },
      { icon: 'redis', name: 'Redis' },
      { icon: 'elasticsearch', name: 'ElasticSearch' },
    ],
  },
]
