import desktopImage from '~/assets/images/services/chatgpt/chat-gpt-expertise.svg'
import { cx } from '~/utils'

import * as containerStyles from './ServicesChatGptExpertiseSection.module.scss'

const ServicesChatGptExpertiseSection = () => (
  <div className="container container-md">
    <div className="row">
      <div
        className={cx(
          'col-12 col-md-6',
          containerStyles.chatGptExpertiseCases__contentOne,
        )}
      >
        <div className={containerStyles.chatGptExpertiseCases__card}>
          <div className={containerStyles.chatGptExpertiseCases__title}>
            Chatbots
          </div>
          <div className={containerStyles.chatGptExpertiseCases__description}>
            Our ChatGPT-powered chatbots are designed to understand, engage, and
            assist with a level of naturalness that boosts the user experience.
          </div>
        </div>
      </div>

      <div
        className={cx(
          'col-12 col-md-6',
          containerStyles.chatGptExpertiseCases__contentTwo,
        )}
      >
        <div className={containerStyles.chatGptExpertiseCases__card}>
          <div className={containerStyles.chatGptExpertiseCases__title}>
            Translation
          </div>
          <div className={containerStyles.chatGptExpertiseCases__description}>
            With our ChatGPT solutions, you can manage translation tasks,
            deliver accurate and contextually relevant translations across
            numerous languages.
          </div>
        </div>
      </div>

      <div
        className={cx(
          'col-12 col-md-6 col-lg-3',
          containerStyles.chatGptExpertiseCases__between,
          containerStyles.chatGptExpertiseCases__wrapperLeft,
        )}
      >
        <div className={containerStyles.chatGptExpertiseCases__card}>
          <div className={containerStyles.chatGptExpertiseCases__title}>
            Creation
          </div>
          <div className={containerStyles.chatGptExpertiseCases__description}>
            Content creation is an art, and Codica’s ChatGPT is the brush. Our
            models generate engaging and creative content, infusing your brand
            with fresh narratives.
          </div>
        </div>

        <div
          className={cx(
            containerStyles.chatGptExpertiseCases__card,
            containerStyles.chatGptExpertiseCases__contentAll,
          )}
        >
          <div className={containerStyles.chatGptExpertiseCases__title}>
            Summary
          </div>
          <div className={containerStyles.chatGptExpertiseCases__description}>
            Our ChatGPT models can clarify lengthy documents / articles /
            reports into concise summaries, saving time and ensuring that
            crucial insights are never buried in verbosity.
          </div>
        </div>
      </div>

      <div
        className={cx(
          'col-6',
          containerStyles.chatGptExpertiseCases__imageDesktop,
        )}
      >
        <img
          src={desktopImage}
          alt="Our QA services | Codica"
          title="Our QA services"
          loading="lazy"
          width={931}
          height={300}
        />
      </div>

      <div
        className={cx(
          'col-12 col-md-6 col-lg-3',
          containerStyles.chatGptExpertiseCases__between,
          containerStyles.chatGptExpertiseCases__wrapperRight,
        )}
      >
        <div className={containerStyles.chatGptExpertiseCases__card}>
          <div className={containerStyles.chatGptExpertiseCases__title}>
            Virtual assistants
          </div>
          <div className={containerStyles.chatGptExpertiseCases__description}>
            At Codica, our ChatGPT-powered virtual assistants can manage
            schedules, respond to questions, and engage in natural, human-like
            conversations.
          </div>
        </div>

        <div
          className={cx(
            containerStyles.chatGptExpertiseCases__card,
            containerStyles.chatGptExpertiseCases__contentAll,
          )}
        >
          <div className={containerStyles.chatGptExpertiseCases__title}>
            Marketing
          </div>
          <div className={containerStyles.chatGptExpertiseCases__description}>
            We employ AI to optimize marketing campaigns, deliver personalized
            content, and engage with potential customers, enhancing your brand’s
            reach and impact.
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default ServicesChatGptExpertiseSection
